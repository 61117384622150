import React, { useEffect } from "react";

import Portfolio from "../components/form/Portfolio";
import { Form, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { portfolioSelector } from "../redux/selectors";
import { useApiMutation } from "../redux/api";

import { setPortfolio } from "../redux/slices/portfolioSlice";

const Render = () => {

  const {t} = useTranslation();
  const portfolio = useSelector(portfolioSelector);
  const dispatch = useDispatch();
  const [api] = useApiMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [portfolio?.reset]);

  const handleOnFinish = async (values) => {
    
    await api({
      method: values?.id && "PUT" || "POST",
      url: "/portfolio",
      body: {
        id: values?.id,
        name: values?.name,
      }
    })
      .then((result) => {
        dispatch(setPortfolio({
          reset: !portfolio?.reset,
          detail: {
            id: result?.data?.id,
            name: result?.data?.name
          }
        }));
        notification.success({
          message: t("main_success"),
          description: t("main_portfolio_success_add"),
          position: "rightTop"
        });
      })
      .catch((err) => {
        notification.error({
          message: t("main_error"),
          description: t("main_portfolio_failed_edit"),
          position: "rightTop"
        });
      })
  }

  return (
    <div>
      <Portfolio 
        form={form}
        t={t}
        data={portfolio?.detail}
        events={{
          handleFinish: handleOnFinish
        }}
      />
    </div>
  ) 
};

export default Render;