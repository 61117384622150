export const indexlist = [
    {
        label: "ТОП-20",
        closedprice: 281.90,
    },{
        label: "MSE-A",
        closedprice: 281.90,
    },{
        label: "MSE-B",
        closedprice: 281.90,
    },
]

export const companydividant = [
    {
        date: "2020",
        per: 1500,
        revenue: 5.2,
        sharep: 70.5,
    },{
        date: "2022",
        per: 1500,
        revenue: 5.2,
        sharep: 70.5,
    },{
        date: "2022.Q2",
        per: 1500,
        revenue: 5.2,
        sharep: 70.5,
    },{
        date: "2022.Q4",
        per: 1500,
        revenue: 5.2,
        sharep: 70.5,
    },{
        date: "2023.Q2",
        per: 1500,
        revenue: 5.2,
        sharep: 70.5,
    }
]

export const globaltrade = {
    status: 0,
    trade: [
        {
            symbol: "NULSBUSD",
            change: 0.3224,
            value: 0.18,
        },
        {
            symbol: "KLAYBUSD",
            change: 0.31,
            value: 0.3224,
        },
        {
            symbol: "KLAYBUSD",
            change: 0.31,
            value: 0.3224,
        },
        {
            symbol: "KLAYBUSD",
            change: 0.31,
            value: 0.3224,
        },
        {
            symbol: "KLAYBUSD",
            change: 0.31,
            value: 0.3224,
        },
        {
            symbol: "KLAYBUSD",
            change: 0.31,
            value: 0.3224,
        },
        {
            symbol: "KLAYBUSD",
            change: 0.31,
            value: 0.3224,
        },
        {
            symbol: "TRXBUSD",
            change: 2.71,
            value: 0.3224,
        },
        {
            symbol: "ADABUSD",
            change: 2.71,
            value: 0.3224,
        },
        {
            symbol: "ADABUSD",
            change: 2.71,
            value: 0.3224,
        },
        {
            symbol: "ADABUSD",
            change: 2.71,
            value: 0.3224,
        },
    ],
};

export const newsdetail = {
    title: "Голомт банкны хувьцааны ханш IPO гаргаснаас хойш -13.5%-иар унаад байна",
    image: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    subtitle: "Голомт банкны хувьцааны ханш IPO гаргаснаас хойш -13.5%-иар унаад байна",
    date: "Jan 10, 2022",
    commentCount: 0,
    type: "Business",
    detail: "<p>Даваа гарагт голомт банк [MSE:GLMT]-ийн хувьцаа -0.71%-иар буурснаар 1,110₮-т хаагдсан нь IPO-р гаргасанаас хойшхи хамгийн доод ханш боллоо. Өөрөө хэлбэл, тус хувьцаа анх олон нийтэд санал болгосон хувьцааны үнэ болох 1,285.0₮-өөсөө доош -13.5%-иар унасан байдалтай байна.</p><p>Хоёрдогч зах зээлийн арилжааны эхлэл нь тоглогчдын хүлээлтэд хүрээгүй болон богино хугацааны хөрөнгө оруулагчид ихээр орсон нь зах зээл дээр нийлүүлэлтийг нэмэгдүүлж, ханшийн дарамтыг үзүүлсээр байгаа юм.</p>",
}

export const companylist = [
    {
        symbol: "MSE:GLMT",
        registeredDate: "2022-08-31",
        shares: "808 657 306",
        branch: null,
        closedprice: "1111 (2023-03-13)"
    }
]

export const companydetail = {
    symbol: "APU",
    name: "АПУ",
    category: "MSE",
    date: "2023-03-15",
    closedprice: "1,495.00",
    currency: "₮",
    previousclose: "100,486",
    traded: "100,486",
    amount: "100,486",
    sharecount: "100,486",
    tradeprice: "100,486",
    eps: "100,486",
    pe: "100,486",
    bv: "100,486",
    market: "100,486",
    industry: "Хүнс",
    introduction: "<p>Даваа гарагт голомт банк [MSE:GLMT]-ийн хувьцаа -0.71%-иар буурснаар 1,110₮-т хаагдсан нь IPO-р гаргасанаас хойшхи хамгийн доод ханш боллоо. Өөрөө хэлбэл, тус хувьцаа анх олон нийтэд санал болгосон хувьцааны үнэ болох 1,285.0₮-өөсөө доош -13.5%-иар унасан байдалтай байна.</p><p>Хоёрдогч зах зээлийн арилжааны эхлэл нь тоглогчдын хүлээлтэд хүрээгүй болон богино хугацааны хөрөнгө оруулагчид ихээр орсон нь зах зээл дээр нийлүүлэлтийг нэмэгдүүлж, ханшийн дарамтыг үзүүлсээр байгаа юм.</p>",
    yearmax: "100,486",
    yearmin: "100,486",
    trade: "100,486",
    income: "100,486",
    nogdolashig: "100,486",
    na: "100,486",
    currency: "MNT"
}

export const suggestion = {
    buy: 20,
    hold: 12,
    sell: 6,
}

export const board = [
    {
        name: "П.Батсайхан",
        role: "ТУЗ-ын дарга"
    },
    {
        name: "П.Батсайхан",
        role: "ТУЗ-ын дарга"
    },
    {
        name: "П.Батсайхан",
        role: "ТУЗ-ын дарга"
    },
    {
        name: "П.Батсайхан",
        role: "ТУЗ-ын дарга"
    },
    {
        name: "П.Батсайхан",
        role: "ТУЗ-ын дарга"
    },
    {
        name: "П.Батсайхан",
        role: "ТУЗ-ын дарга"
    },
    {
        name: "П.Батсайхан",
        role: "ТУЗ-ын дарга"
    },
    {
        name: "П.Батсайхан",
        role: "ТУЗ-ын дарга"
    },
];

export const ownershipstatus = [
    {
        name: "Шунхлай холдинг ХХК",
        status: "main",
        share: 60,
    },    
    {
        name: "Hieneken Asia Pacific Pte LTD",
        status: "main",
        share: 15,
    },    
    {
        name: "Steppe beverage KFT",
        status: "main",
        share: 15,
    },    
    {
        name: "Steppe beverage KFT",
        status: "sub",
        share: 10,
    },    
];

export const ownershipmovement = [
    {
        totalshare: 1064181553,
        govermentshare: 0,
        govermentowner: 5777,
        govermentpercent: 3,
        impactfulshare: 91.84,
        impactfulowner: 5777,
        impactfulpercent: 3,
        smallowners: 27.4,
        begindate: '2023.02.03.',
        daterange: '2023.02.03 - 2023.03.02',
        enddate: '2023.03.02'
    },{
        totalshare: 1064181553,
        govermentshare: 0,
        govermentowner: 5777,
        govermentpercent: 3,
        impactfulshare: 91.84,
        impactfulowner: 5777,
        impactfulpercent: 3,
        smallowners: 27.4,
        daterange: '2023.02.03 - 2023.03.02',
        begindate: '2023.02.03.',
        enddate: '2023.03.02'
    },{
        totalshare: 1064181553,
        govermentshare: 0,
        govermentowner: 5777,
        govermentpercent: 3,
        impactfulshare: 91.84,
        impactfulowner: 5777,
        daterange: '2023.02.03 - 2023.03.02',
        impactfulpercent: 3,
        smallowners: 27.4,
        begindate: '2023.02.03.',
        enddate: '2023.03.02'
    },{
        totalshare: 1064181553,
        govermentshare: 0,
        govermentowner: 5777,
        govermentpercent: 3,
        daterange: '2023.02.03 - 2023.03.02',
        impactfulshare: 91.84,
        impactfulowner: 5777,
        impactfulpercent: 3,
        smallowners: 27.4,
        begindate: '2023.02.03.',
        enddate: '2023.03.02'
    },
];

export const selectlist = [
    {
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },{
        value: "apu",
        label: "АПУ"
    },
]

export const nottradedeal = [
    {
        symbol: "apu",
        name: "АПУ",
        status: "Үүргийн гүйцэтгэл",
        amount: 12341,
        date: "2023-03-31"
    },{
        symbol: "apu",
        name: "АПУ",
        status: "Үүргийн гүйцэтгэл",
        amount: 1234123,
        date: "2023-03-31"
    },{
        symbol: "apu",
        name: "АПУ",
        status: "Шүүхийн шийдвэр",
        amount: 23454,
        date: "2023-03-31"
    },{
        symbol: "apu",
        name: "АПУ",
        status: "Бэлэглэл",
        amount: 234,
        date: "2023-03-3"
    },
];

export const companyanalysis = [
    {
        date: "2019",
        percent: 20,
        revenue: 12
    },{
        date: "2020",
        percent: 20,
        revenue: 12
    },{
        date: "2021",
        percent: 20,
        revenue: 12
    },{
        date: "2022",
        percent: 20,
        revenue: 12
    },
]
export const companysuggestion = [
    {
        timerange: "now",
        buy: 20,
        hold: 12,
        sell: 6,
        date: "2023.03.01"
    },{
        timerange: "quarter",
        buy: 20,
        hold: 12,
        sell: 6,
        date: "2023.03.01"
    },{
        timerange: "halfyear",
        buy: 20,
        hold: 12,
        sell: 6,
        date: "2023.03.01"
    },
]

export const analysis = [
    {
        name: "НЭгж хувьцаанд ногдох ашиг / EPS",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Дансны үнэ / BV",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Үнэ ашгийн харьцаа / PE",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Дансны үнийн харьцаа / PB",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Үнэ борлуулалтын харьцаа / PS",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Нийт хөрөнгийн өгөөж /ROA/",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Өөрийн хөрөнгийн өгөөж",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Нийт ашгийн маржин",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Ногдол ашгийн өгөөж",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    }
]

export const analysis_backup = [
    {
        year: '2019',
        eps: 15,
        bv: 1,
        pe: 1,
        pb: 1,
        ps: 1,
        roa: 15,
        roe: 15,
        ratiorevenue: 1895,
        marginpureincome: 185,
        ratioincome: 18,
    },{
        year: '2020',
        eps: 5,
        bv: 1,
        pe: 1,
        pb: 1,
        ps: 1,
        roa: 5,
        roe: 5,
        ratiorevenue: 15,
        marginpureincome: 15,
        ratioincome: 15,
    },,{
        year: '2021',
        eps: 15,
        bv: 1,
        pe: 1,
        pb: 1,
        ps: 1,
        roa: 15,
        roe: 15,
        ratiorevenue: 15,
        marginpureincome: 15,
        ratioincome: 1785,
    },{
        year: '2022',
        eps: 5,
        bv: 1,
        pe: 1,
        pb: 1,
        ps: 1,
        roa: 5,
        roe: 5,
        ratiorevenue: 15,
        marginpureincome: 15,
        ratioincome: 15,
    },

]

export const income = [
    {
        name: "Цэвэр борлуулалт",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "ББӨ",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Бусад орлого",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Ногдол ашгийн өгөөж",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    }
]

export const balance = [
    {
        name: "Нийт хөрөнгө",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Эргэлтийн хөрөнгө",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Эргэлтийн бус хөрөнгө",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Өр төлбөр",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    },{
        name: "Эзэмшигчийн өмч",
        2020: 15,
        2021: 6,
        2022: 16,
        2023: 16
    }
];

export const newslist = [
    {
        id: 1,
        image: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
        title: "ПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Some representative placeholder content for the second slide. Some representative placeholder content for the second slide",
        source: "MarketInfo", 
        date: "Jan 10, 2022",    
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 1,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        image: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
        title: "АПU компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide. Som representative placeholder content for the second slide. Som representative placeholder content for the second slide. Som representative placeholder content for the second slide.Som representative placeholder content for the second slide. Som representative placeholder content for the second slide.Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 3,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани о лон улсын хөрөнгийн зах зээлд гарна",
        image: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 1,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        image: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
        title: "APU компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 4,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        image: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
        title: "АpУ компани  олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 5,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        image: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 1,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 1,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
    {
        id: 1,
        title: "АПУ компани олон улсын хөрөнгийн зах зээлд гарна",
        subtitle: "Som representative placeholder content for the second slide",
        source: "MarketInfo",
        date: "Jan 10, 2022", 
        commentCount: 0,
        type: "Business",
        seenCount: "1.5M",
        rate: 2,
        file: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg",
    },
];

export const localnews = [
    {   
        icon: "/static/img/icon3.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon4.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon5.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon6.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon7.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },
    {
        icon: "/static/img/icon8.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon9.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon10.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon11.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon12.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },{   
        icon: "/static/img/icon3.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon4.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon5.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon6.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon7.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },
    {
        icon: "/static/img/icon8.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon9.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon10.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon11.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon12.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },{   
        icon: "/static/img/icon3.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon4.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon5.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon6.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon7.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },
    {
        icon: "/static/img/icon8.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon9.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon10.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon11.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon12.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },{   
        icon: "/static/img/icon3.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon4.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon5.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon6.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon7.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },
    {
        icon: "/static/img/icon8.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon9.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon10.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon11.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon12.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },{   
        icon: "/static/img/icon3.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon4.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon5.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon6.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon7.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },
    {
        icon: "/static/img/icon8.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        icon: "/static/img/icon9.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        icon: "/static/img/icon10.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        icon: "/static/img/icon11.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        icon: "/static/img/icon12.png",
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },
];

export const globalnews = [
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ТОП-20 индекс +3%-ийн өсөлт үзүүллээ",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "2022 оны 3-р улирлаас өсөлт гарах төлөвтэй байна",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "ОХУ-ын зах зээлд бүтээгдэхүүнээ борлуулалт эхэллээ",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Хувьцаа эзэмшүүлэх хөтөлбөр хэрэгжүүлнэ",
    },
    {
        date: "2023.02.03", 
        source: "marketinfo.mn",
        title: "Тавантолгой-ийн борлуулалт буурав",
    },
    
];

export const banner = {
    horizontal: "/static/img/banner_r.png",
    vertical: "https://www.apu.mn/wp/wp-content/uploads/2020/09/161577-25042018-1524633837-1842895212-LXGR1-1.jpg"
};

export const trade = [
    {
        id: 1,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        price: 15048500,
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 2,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        price: 15048500,
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 3,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        price: 15048500,
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 4,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        price: 15048500,
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },
];

export const orderhistory = [
    {
        sell_price: 12334,
        sell_amount: 123,
        buy_price: 12334,
        buy_amount: 123,
    },{
        sell_price: 12334,
        sell_amount: 123,
        buy_price: 12334,
        buy_amount: 123,
    },{
        sell_price: 12334,
        sell_amount: 123,
        buy_price: 12334,
        buy_amount: 123,
    },{
        sell_price: 12334,
        sell_amount: 123,
        buy_price: 12334,
        buy_amount: 123,
    },{
        sell_price: 12334,
        sell_amount: 123,
        buy_price: 12334,
        buy_amount: 123,
    },{
        sell_price: 12334,
        sell_amount: 123,
        buy_price: 12334,
        buy_amount: 123,
    },{
        sell_price: 12334,
        sell_amount: 123,
        buy_price: 12334,
        buy_amount: 123,
    },
]

export const packtrade = [

]

export const set_trade_main = [
    {
        id: 1,
        symbol: "APU",
        price: 15048500,
        tradeamount: 1002003,
        total: "+1M%",
        closedprice: 2000,
    },
    {
        id: 2,
        symbol: "APU",
        price: 15048500,
        tradeamount: 1002003,
        total: "+1M%",
        closedprice: 2000,
    },
    {
        id: 3,
        symbol: "APU",
        price: 15048500,
        tradeamount: 1002003,
        total: "+1M%",
        closedprice: 2000,
    },
    {
        id: 4,
        symbol: "APU",
        price: 15048500,
        tradeamount: 1002003,
        total: "+1M%",
        closedprice: 2000,
    },
    {
        id: 5,
        symbol: "APU",
        price: 15048500,
        tradeamount: 1002003,
        total: "+1M%",
        closedprice: 2000,
    },
    {
        id: 6,
        symbol: "APU",
        price: 15048500,
        tradeamount: 1002003,
        total: "+1M%",
        closedprice: 2000,
    },
    {
        id: 7,
        symbol: "APU",
        price: 15048500,
        tradeamount: 1002003,
        total: "+1M%",
        closedprice: 2000,
    },
]
export const insider_main = [
    {
        id: 1,
        name: "APU", 
        symbol: "APU",
        tradeamount: 1002003,
        status: "Захиалсан",
    },
    {
        id: 2,
        name: "APU", 
        symbol: "APU",
        tradeamount: 1002003,
        status: "Захиалсан",
    },
    {
        id: 3,
        name: "APU", 
        symbol: "APU",
        tradeamount: 1002003,
        status: "Захиалсан",
    },
    {
        id: 4,
        name: "APU", 
        symbol: "APU",
        tradeamount: 1002003,
        status: "Захиалсан",
    },
    {
        id: 5,
        name: "APU", 
        symbol: "APU",
        tradeamount: 1002003,
        status: "Захиалсан",
    },
    {
        id: 6,
        name: "APU", 
        symbol: "APU",
        tradeamount: 1002003,
        status: "closed",
    },
    {
        id: 7,
        name: "APU", 
        symbol: "APU",
        tradeamount: 1002003,
        status: "closed",
    },
]


export const otc_market = [
    {
        id: 1,
        name: "APU", 
        tradeamount: 1000,
        status: "closed",
    },
    {
        id: 2,
        name: "APU", 
        tradeamount: 1000,
        status: "closed",
    },
    {
        id: 3,
        name: "APU", 
        tradeamount: 1000,
        status: "closed",
    },
    {
        id: 4,
        name: "APU", 
        tradeamount: 1000,
        status: "closed",
    },
    {
        id: 5,
        name: "APU", 
        tradeamount: 1000,
        status: "closed",
    }
]
export const non_trade_deal = [
    {
        id: 1,
        symbol: "APU",
        tradeamount: 1000,
        status: "Бэлэглэл",
    },
    {
        id: 2,
        symbol: "APU",
        tradeamount: 1000,
        status: "Бэлэглэл",
    },
    {
        id: 3,
        symbol: "APU",
        tradeamount: 1000,
        status: "Шүүх.Шийдвэр",
    },
    {
        id: 4,
        symbol: "APU",
        tradeamount: 1000,
        status: "Үүрэг.Гүйцэтгэл",
    },
    {
        id: 5,
        symbol: "APU",
        tradeamount: 1000,
        status: "Бэлэглэл",
    },
]

export const signal_main = [
    {
        id: 1,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id: 2,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id: 3,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id: 4,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id: 5,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id: 6,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id: 7,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id: 8,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id:9,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
    {
        id: 10,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        signal: "52 долоо хоногын дээд",
    },
];
export const analysis_main = [
    {
        id: 1,
        name: "Bitcoin",
        amount: "₮5,777",
    },
    {
        id: 2,
        name: "Bitcoin",
        amount: "₮5,777",
    },
    {
        id: 3,
        name: "Bitcoin",
        amount: "₮5,777",
    },
    {
        id: 4,
        name: "Bitcoin",
        amount: "₮5,777",
    },
    {
        id: 5,
        name: "Bitcoin",
        amount: "₮5,777",
    },
    {
        id: 6,
        name: "Bitcoin",
        amount: "₮5,777",
    },
    {
        id: 7,
        name: "Bitcoin",
        amount: "₮5,777",
    },
];

export const incdec_main = [
    {
        id: 1,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 2,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 3,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 4,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 5,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 6,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 7,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },
];
export const other_main = [
    {
        id: 1,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    },{
        id: 2,
        symbol: "APU",
        closedprice: 2000,
        change: 5.1,
        changep: 27.4,
        amount: 100486,
        total: "+1M%",
        high: 1499.00,
        low: 1499.00,
        date: '2023.03.13',
        openprice: 1499.00,
    }
];

export const research = [
    {
        id: 1,
        date: "2023.03.13",
        name: "АПУ ХК судалгаа",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
    {
        id: 2,
        date: "2023.03.13",
        name: "АПУ ХК судалгаа",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
    {
        id: 3,
        date: "2023.03.13",
        name: "АПУ ХК судалгаа",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
    {
        id: 4,
        date: "2023.03.13",
        name: "АПУ ХК судалгаа",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
    {
        id: 5,
        date: "2023.03.13",
        name: "АПУ ХК судалгаа",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
];

export const report = [
    {
        date: "2023.03.13",
        name: "Маркет тайлан 2022.12",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
    {
        date: "2023.03.13",
        name: "Маркет тайлан 2022.12",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
    {
        date: "2023.03.13",
        name: "Маркет тайлан 2022.12",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
    {
        date: "2023.03.13",
        name: "Маркет тайлан 2022.12",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
    {
        date: "2023.03.13",
        name: "Маркет тайлан 2022.12",
        source: "marketinfo.mn",
        file: "https://file.download.com"
    },
];

export const infographic = [
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info1.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info2.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info3.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info4.png",
    },{
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info1.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info2.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info3.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info4.png",
    },{
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info1.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info2.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info3.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info4.png",
    },{
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info1.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info2.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info3.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info4.png",
    },{
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info1.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info2.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info3.png",
    },
    {
        name: "IPO гаргасан компаниудын зах зээлд байр суурь",
        description: "Энэхүү үнэлгээ 2017 оноос хойш IPO гаргасан компаниудыг харьцуулсан ба ЭЗҮ-р харуулав.",
        source: "www.marketinfo.mn",
        image: "/static/img/info4.png",
    },
];




export const watchlist = [
    {
        type: "IPO",
        company: "АПУ",
        closedprice: 88.65,
        change: 30.17
    },
    {
        type: "Бонд",
        company: "АПУ",
        closedprice: 88.65,
        change: 30.17
    },
    {
        type: "ХБҮЦ",
        company: "АПУ",
        closedprice: 88.65,
        change: 30.17
    },
    {
        type: "ХОСан",
        company: "АПУ",
        closedprice: 88.65,
        change: 30.17
    },
];

export const invest_main = [
    {
        name: "Гадаад",
        buy: 3325,
        sell: 32223,
        difference: 32442
    },
    {
        name: "Дотоод",
        buy: 43435,
        sell: 54545,
        difference: 65656
    },
]

export const ipo = [
    {
        symbol: "IPO",
        company: "#SOL",
        closedprice: 88.65,
        change: 30.17
    },
    {
        symbol: "IPO",
        company: "#SOL",
        closedprice: 88.65,
        change: 30.17
    },
    {
        symbol: "IPO",
        company: "#SOL",
        closedprice: 88.65,
        change: 30.17
    },
    {
        symbol: "IPO",
        company: "#SOL",
        closedprice: 88.65,
        change: 30.17
    },
];

export const calendar = [
    {
        date: "2023.01.25",
        title: "New category added",
        subtitle: "<<Mobile phone>>",
    },
    {
        date: "2023.01.25",
        title: "New category added",
        subtitle: "<<Mobile phone>>",
    },
    {
        date: "2023.01.25",
        title: "New category added",
        subtitle: "<<Mobile phone>>",
    },
    {
        date: "2023.01.25",
        title: "New category added",
        subtitle: "<<Mobile phone>>",
    },
    {
        date: "2023.01.25",
        title: "New category added",
        subtitle: "<<Mobile phone>>",
    },
    {
        date: "2023.01.25",
        title: "New category added",
        subtitle: "<<Mobile phone>>",
    },
    {
        date: "2023.01.25",
        title: "New category added",
        subtitle: "<<Mobile phone>>",
    },
];

export const company_intro = 
    {
        title: "Танилцуулга",
        description: "АПУ ХК нь Монгол Улсын архи, пиво, ундаа, сүү болон цэвэршүүлсэн усны томоохон үйлдвэрлэгч бөгөөд архины гол экспортлогч компани юм. Компанийн түүх 1924 онд Архи пивоны завод нэртэйгээр байгуулагдсан бөгөөд 1927 онд Чех улсын технологиор анхны пивоны үйлдвэрээ ашиглалтанд оруулж байсан юм. Зах зээлийн эдийн засагт шилжихээс өмнө тус компанид улсын болон ЗХУ болон бусад улсын тусламжтайгаар техник технологын шинэчлэлийг хийж, зах зээл дээр шинэ шинэ брендүүдийг нэвтрүүлж байсан түүхтэй.",
    };
export const company_statistic = 
    {
        title: "Онцлох статистик",
        up: "52 д.х. дээд",
        down: "52 д.х. доод",
        sales: "Борлуулалт",
        profit: "Ашиг",
        dividens: "Ногдол ашиг",
        yield: "НА -ийн өгөөж",
        amount: "100,486",
        billion: "тэр.₮",
    };


export const company_advice =
    {
        buy: 12,
        hold: 6,
        sell: 20,
        date: "2023.03.01"
    }

export const plan = [
    {
        name: "trade",
        free: true,
        month: true,
        halfyear: true,
        year: true,
    },{
        name: "today",
        free: true,
        month: true,
        halfyear: true,
        year: true,
    },{
        name: "board",
        free: true,
        month: true,
        halfyear: true,
        year: true,
    },{
        name: "change",
        free: false,
        month: true,
        halfyear: true,
        year: true,
    },{
        name: "movement",
        free: false,
        month: true,
        halfyear: true,
        year: true,
    },{
        name: "central",
        free: false,
        month: true,
        halfyear: true,
        year: true,
    },{
        name: "weekly",
        free: false,
        month: true,
        halfyear: true,
        year: true,
    },{
        name: "others",
        free: false,
        month: true,
        halfyear: true,
        year: true,
    },
]

export const app_banner = [
   {file: "/static/img/banner.png"} ,
   {file: "/static/img/banner2.png"} ,
   {file: "/static/img/banner.png"} ,
   {file: "/static/img/banner2.png"} ,
]