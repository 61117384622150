import { createListenerMiddleware, combineReducers, configureStore } from "@reduxjs/toolkit";

import { apiBase } from "./api";
import authReducer from "./slices/authSlice";
import companyReducer from "./slices/companySlice";
import loaderReducer from "./slices/loaderSlice";
import dataReducer from "./slices/dataSlice";
import modalReducer from "./slices/modalSlice";
import shareReducer from "./slices/shareSlice";
import portfolioReducer from "./slices/portfolioSlice";
import watchlistReducer, { watchlistMiddleware } from "./slices/watchlistSlice";



const rootReducer = combineReducers({
  [apiBase.reducerPath]: apiBase.reducer,
  auth: authReducer,
  company: companyReducer,
  data: dataReducer,
  loader: loaderReducer,
  modal: modalReducer,
  share: shareReducer,
  portfolio: portfolioReducer,
  watchlist: watchlistReducer
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      watchlistMiddleware.middleware,
      apiBase.middleware,
    ],
    preloadedState,
  });
};
