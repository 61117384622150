import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Render = ({
  index,
  url,
  image,
  preview,
  outerClassName
}) => {

  return (
    <Link to={url} target="_blank" className={" " + outerClassName} key={index}>
      <Image className="" src={image && image || ""} preview={preview}/>
    </Link>
  )
}

export default Render;