// Main components
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// Sub components
import Banner from "./Banner";
import TradeToday from "./TradeToday";
import Ipo from "./Watchlist";
import Watchlist from "./Ipo";
import Calendar from "./Calendar";
import DownloadApp from "./DownloadApp";

// UI components
import { Breadcrumbs, Divider, Typography, Link } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Col, Row, Tag, Spin } from "antd";
import styled from "@emotion/styled";

// Global state components
import { useApiMutation, useGetApiQuery, useGetRecordItemsQuery } from "../../../redux/api";
import { setStreamList } from "../../../redux/slices/companySlice";
import { authSelector, companySelector, streamSelector } from "../../../redux/selectors";

import {
  globaltrade,
  newslist,
  localnews,
  globalnews,
  trade,
  banner,
  research,
  report,
  infographic,
  watchlist,
  ipo,
  app_banner,
} from "../../../utils/data";

function SideBar() {
  const { t } = useTranslation();
  const [banner, setBanner] = useState([]);
  const [api] = useApiMutation();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {

    var {data} = await api({method: "GET", url: "/home/banners"});
    // console.log("$:/data/ ", data);
    if(data.data) {
      setBanner(data.data);
    }
  }

  return (
    <div>
      <TradeToday t={t} />
      <hr className="my-4"/>
      {/* <Banner t={t} layout="horizontal" data={banner?.data} link="/testbanner" /> */}
      <Banner t={t} timeout={6000} data={banner.filter((e) => {if(e.position == 1) {return e}})} index={0} />
      <hr className="my-4"/>
      <Watchlist t={t} />
      {/* <hr className="my-4"/>
      <Ipo t={t} data={watchlist && watchlist || []} /> */}
      {/* <hr className="my-4"/>
      <Calendar t={t} data={calendar} /> */}
      <hr className="my-4"/>
      {/* <DownloadApp t={t} data={app_banner && app_banner || []} /> */}
      <hr className="my-4"/>
      <Banner t={t} timeout={5000}data={banner.filter((e) => {if(e.position == 2) {return e}})} index={1} />
    </div>
  );
}

export default SideBar;
