import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";
import _ from "lodash";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decode = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  var isExpired = _.gt(currentTime, decode.exp);

  // console.log("$:/jwt/decode ", decode.exp);
  // console.log("$:/jwt/currentTime ", currentTime);
  // console.log("$:/jwt/isExpired ", isExpired);

  return isExpired;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { verify, sign, isValidToken, setSession };
