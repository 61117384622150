import { createListenerMiddleware, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { loadState, saveState } from "../../utils/loadStorage";

let initialstate = {
  list: loadState(),
};

const slice = createSlice({
  name: "watchlist",
  initialState: initialstate,
  reducers: {
    setWatchlist: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const watchlistMiddleware = createListenerMiddleware();

watchlistMiddleware.startListening({
  matcher: isAnyOf(slice.actions.setWatchlist),
  effect: (action, listenerApi) => {
    if(action.type == "watchlist/setWatchlist") {
      saveState(action.payload.list);
    }
  }
});

export const { setWatchlist } = slice.actions;
export default slice.reducer;
