

function Card({
  title,
  subtitle,
  image,
  textarea,
  outerClassName,
  innerClassName
}) {

  return (
    <div className={`${outerClassName}`}>    
      {image && <div style={{backgroundImage: `url(${image})`, backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat"}} className={`${innerClassName}`}/>}
      {textarea && <div dangerouslySetInnerHTML={{ __html: textarea }} />}
    </div>
  );
};


export default Card;