import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "company",
  initialState: {
    list: {
      list: [],
      detail: {},
      status: "",
      message: "",
      paging: {
        limit: 20,
        current: 1,
      },
    },
    detail: {
      code: '90',
      symbol: "APU"
    },
    history: [],
    trade: {},
    intro: {},
    news: [],
    statistics: [],
    board: [],
    ownershipmovement: [],
    ownershipnontrade: [],
    ownershipmain: [],
    financebalance: [],
    financehistorygraph: [],
    financetrade: [],
    financepacktrade: [],
    financeorderhistory: [],
    financeratio: [],
    financedivident: [],
  },
  reducers: {
    setCompany: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setCompany } = slice.actions;

export default slice.reducer;
