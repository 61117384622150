import React from "react";

import Card09 from "../../../components/card/Card09";
import Table from "../../../components/table/Watchlist";

const TradeBoard = ({t, title, data, link}) => {

  return (
    <div> 
      <Card09
        title={t("main_trade_ipo")}
        link="https://trade.marketinfo.mn"
        /> 
      <div className="watchlist table-small">
        <Table rowClassName="background-default" t={t} data={data} />
      </div>
    </div>
  )
};

export default TradeBoard;