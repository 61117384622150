import React, { useState } from "react";

import { Select, Space, Tag } from "antd";

const Render = ({t, data}) => {

  // console.log("$:/data ", data);
  const [value, setValue] = useState("mse");

  const tagRender = ({label, value}) => {
    return (
      <Tag
        color={`${data?.status == 'Зах зээл нээлттэй' && 'green' || 'red'}`}
      >
        <div className="capitalize">{value}: {data?.status}</div>
      </Tag>
    )
  }

  const options = [
    {
      label: `MSE`,
      value: "MSE"
    },
    {
      label: `UBEX`,
      value: "UBEX"
    }
  ]

  const selectProps = {
    tagRender,
    mode: "mul",
    style: { width: "100%"},
    defaultValue: ["MSE"],
    bordered: false,
    value,
    options,
    onChange: newValue => {
      setValue(newValue)
    },
  }

  return (
    <div>
      {/* <Select {...selectProps} /> */}
      <Select
        bordered={false}
        mode="multiple"
        tagRender={tagRender}
        defaultValue={['MSE']}
        style={{ width: '100%' }}
        options={options}
      />
    </div>
  )
}

export default Render;