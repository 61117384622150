import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "http://18.180.154.209:8080/",
});

axiosInstance.defaults.headers = {
  "Access-Control-Allow-Origin": "*",
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something wrong with api"
    )
);

export default axiosInstance;
