import React from "react";

import { Link } from "react-router-dom";

const Bko = ({title, outerClassName}) => {

  return (
    <Link to="#" className={"font-bold mb-2 " + outerClassName }>
      {title}
    </Link>
  )
}

export default Bko;