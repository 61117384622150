import React from "react";

const Card = ({
  title,
  link,
  image,
}) => {
 

  return (
    <div>
      <div className="flex mb-2 justify-start items-center">
        <div className="">
          {title}
        </div>
        {/* <div className="ml-2">
          {link && <a href={link} >View More</a>}
        </div> */}
      </div>
    </div>
  ) 
};

export default Card;