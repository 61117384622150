import React from "react";
import { useTranslation } from "react-i18next";

import { setCredentials } from "../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import { 
  Button,
  Input,
  Checkbox,
  message,
} from "antd";
import {
  GoogleOutlined
} from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router-dom";
import { authSelector, modalSelector } from "../redux/selectors";
import { setModal } from "../redux/slices/modalSlice";

import Form from "../components/auth/Registration";
import { useApiMutation } from "../redux/api";

const Render = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const modal = useSelector(modalSelector);
  const navigate = useNavigate();
  const [api] = useApiMutation();
  
  const handleLogin = async (e) => {
    
    const { accessToken, tokenId, profileObj, googleid, tokenObj } = e;

    if(!!tokenId) {
      localStorage.setItem("marketinfo", tokenId);
      localStorage.setItem("marketprofile", JSON.stringify({isAuthenticated: true, user: profileObj}));

      const token = localStorage.getItem("marketinfo");

      dispatch(
        setCredentials({
          token: tokenId,
          user: profileObj,
          isAuthenticated: true,
        })
      );

      const response = await api({
        method: "POST",
        url: "/auth/google"
      });

      if(!response.error) {
        dispatch(
          setModal({
            status: "success",
            message: "",
            modal: false
          })
        )
        // console.log("$:/auth/redirect ", auth.redirect);
        if(auth.redirect) {
          navigate(auth.redirect);
        }
      } else {
        dispatch(
          setModal({
            status: "error",
            message: "Алдаа гарлаа, дахин оролдоно уу!"
          })
        )
      }

    };
  };

  const handleMode = (value) => {

    dispatch(
      setModal({
        mode: value?.mode
      })
    )
  }

  return (
    <div className="text-center">
      <div className="flex justify-center absolute inset-x-0 -top-10">
        <div className="bg-gradient-to-b from-blue-700 opacity-80 w-20 h-20 rounded-full p-4 flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>
        </div>
      </div>
      <div className="h-6"></div>
      <div className="text-xl text-blue-700 font-bold mb-4">{t("auth_form_signin")}</div>
      <div className="flex justify-center">
        <div className="mr-2">
          <GoogleLogin 
            clientId={"303902114425-r5rfe11em7pidemi8loeqsin9kp66c1o.apps.googleusercontent.com"} //process.env.REACT_APP_GOOGLE_CLIENTID
            // render={renderProps => (
            //   <div >
            //     <Button type="text" className="h-8" onClick={renderProps.onClick} disabled={renderProps.disabled} icon={<img src="/static/img/google.png" width="26px" height="26px" />}>Google хаягаар нэвтрэх</Button>
            //   </div>
            // )}
            // render={renderProps => (
            //   <div className="border border-gray-300 rounded w-12 p-1">
            //     <Button type="text" className="h-8" onClick={renderProps.onClick} disabled={renderProps.disabled} icon={<img src="/static/img/google.png" width="26px" height="26px" />} />
            //   </div>
            // )}
            buttonText="Google хаягаар нэвтрэх"
            onSuccess={handleLogin}
            onFailure={handleLogin}
            cookiePolicy={'single_host_origin'}
          />
        </div>
        <div className={" " + modal.status == "error" && "text-red-500"} >{modal.message}</div>
        {/* <div>
          <GoogleLogin 
            clientId={"303902114425-r5rfe11em7pidemi8loeqsin9kp66c1o.apps.googleusercontent.com"} //process.env.REACT_APP_GOOGLE_CLIENTID
            render={renderProps => (
              <div className="border border-gray-300 rounded w-12 p-1">
                <Button type="text" className="h-8" onClick={renderProps.onClick} disabled icon={<img src="/static/img/facebook.svg" width="26px" height="26px" />} />
              </div>
            )}
            buttonText=""
            onSuccess={handleLogin}
            onFailure={handleLogin}
            cookiePolicy={'single_host_origin'}
          />
        </div> */}
      </div>
      {/* <div className="text-gray-400 text-center my-6">{t("auth_form_else")}</div>
      <div>
        <Form 
          t={t}
          data={{
            mode: modal?.mode
          }}
          events={{
            handleMode: handleMode,
          }}
        />
      </div> */}
    </div>
  )
};

export default Render;