import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  token: null,
  user: {
    imageUrl: ""
  },
  initials: {}
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    userLogginOut: (state, {}) => {
      state.isAuthenticated = false;
      state.isInitialized = true;
      state.token = "";
      state.role = "";
      state.user = {};
      state.mode = "login";
    },
  },
});

export const { setCredentials, userLogginOut } = slice.actions;

export default slice.reducer;