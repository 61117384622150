import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "portfolio",
  initialState: {
    list: [],
    detail: {},
    helpers: {},
    reset: false,
    paging: {
      limit: 20,
      current: 1,
    },
  },
  reducers: {
    setPortfolio: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setPortfolio } = slice.actions;

export default slice.reducer;
