export const loadState = () => {
    try {
        var serializedState = localStorage.getItem('watchlist');
        if (serializedState === null) {
            return [];
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return [];
    }
}

export const saveState = (state) => {
    try {
        var serializedState = JSON.stringify(state);
        localStorage.setItem("watchlist", serializedState);
    } catch(e) {
        // console.log("$:/e ", e);
    }
}