import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  BellOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  SendOutlined
} from "@ant-design/icons";

import Card06 from "../card/Card06";

const NavbarSubmenu = ({ t }) => {
  const navigate = useNavigate();
  
  const onClick = (e) => {
    navigate("/" + e.key);
  };

  const items = [
    {
      label: t("menu_announcement"),
      key: "/",
      outerClassName: "col-span-3",
      icon: <BellOutlined className="text-xs" /> ,
    },
    {
      label: t("menu_cookie"),
      key: "/news",
      outerClassName: "col-span-3",
      icon: <DatabaseOutlined className="text-xs" />,
    },
    {
      label: t("menu_downloads"),
      key: "/package",
      outerClassName: "col-span-3",
      icon: <DownloadOutlined className="text-xs" />,
    },
    {
      label: t("menu_online_support"),
      key: "/watchlist",
      outerClassName: "col-span-3",
      icon: <SendOutlined className="text-xs" />
    },
  ]

  return (
    <div className="flex justify-between">
      {items.map((item, index) => (
        <Card06 
          outerClassName={item?.outerClassName}
          index={index} 
          title={item?.label}
          icon={item?.icon} 
          link={item?.key} 
        />
      ))}
    </div>
  );
}

export default NavbarSubmenu;
