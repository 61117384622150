import { createSelector } from "reselect";

const selfSelector = (state) => state;

export const cameraSelector = createSelector(
  selfSelector,
  (state) => state.cow
);

export const authSelector = (state) => {
  if (!!state.auth) {
    return state.auth;
  } else {
    return null;
  }
};

export const companySelector = createSelector(
  selfSelector,
  (state) => state?.company
);

export const watchlistSelector = createSelector(
  selfSelector,
  (state) => state?.watchlist
);

export const dataSelector = createSelector(
  selfSelector,
  (state) => state?.data
);

export const shareSelector = createSelector(
  selfSelector,
  (state) => state?.share
)

export const modalSelector = createSelector(
  selfSelector,
  (state) => state?.modal
);

export const portfolioSelector = createSelector(
  selfSelector,
  (state) => state?.portfolio
);

export const loaderSelector = (state) => {
  if (!!state.loader) {
    return state.loader;
  } else {
    return null;
  }
};

export const verifyIdTokenSelector = createSelector(
  authSelector,
  (user = { verifyIdToken: null }) => {
    // console.log("$:/user/token ", user);
    const { verifyIdToken } = user;
    // console.log("$:/user/verifytoken ", verifyIdToken);
    return user.verifyIdToken;
  }
);
