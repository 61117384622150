import React, { useEffect } from "react";

import {
  Button,
  Form,
  Input
} from "antd";

const Render = ({t, data, events, form}) => {
 
  return (
    <Form
      layout=""
      form={form}
      initialValues={{
        id: data?.id && data?.id || null,
        name: data?.name && data?.name || null
      }}
      onFinish={events?.handleFinish}
    > 
      <Form.Item
        name="id"
        className="hidden"
      >
        <Input />
      </Form.Item>
      <Form.Item 
        name="name"
        label={t("main_portfolio_name")}
        rules={[{ required: true }]}
        className="w-48"
      >
        <Input placeholder={t("main_portfolio_name")} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">{t("main_portfolio_button")}</Button>
      </Form.Item>
    </Form>
  );
};

export default Render;