import React, { useEffect, useState } from "react";

import Card09 from "../../../components/card/Card09";
import Table from "../../../components/table/Ipo";
import {
  Form 
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { companySelector, watchlistSelector } from "../../../redux/selectors";
import { setWatchlist } from "../../../redux/slices/watchlistSlice";

const TradeBoard = ({t, title, data, link}) => {

  const [ state, setState ] = useState([]);
  const [form] = Form.useForm();
  const company = useSelector(companySelector);
  const watchlist = useSelector(watchlistSelector);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   var localdata = localStorage.getItem("watchlist");

  //   setState(JSON.parse(localdata));
  // }, []);

  const handleOnClick = (e) => {
    // console.log("$:/e ", e);
    // console.log("$:/list ", company.list);
    var data = company.list.filter((item) => { if(item.code === e.symbol) {return item}});
    
    // console.log("$:/data ", data);

    dispatch(setWatchlist({
      list: [
        ...watchlist.list,
        data[0]
      ]
    }));

    form.resetFields();
  };

  // console.log("$:/watchlist ", watchlist);

  return (
    <div> 
      <Card09
        title={t("main_trade_watchlist")}
        link="https://trade.marketinfo.mn"
        /> 
      <div className="watchlist table-small">
        <Table form={form} rowClassName="background-default" t={t} data={{
          watchlist: watchlist.list,
          company: company?.list
        }} events={{onClick: handleOnClick}} />
      </div>
    </div>
  )
};

export default TradeBoard;