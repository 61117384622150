import React from "react";

import { Link } from "react-router-dom";

function NavbarLogo() {
  
  return (
    <Link to="/">
      <img src="/static/img/logo.png" width="90" height="50" />
    </Link>
  );
}

export default NavbarLogo;
