import React from "react";
import styled from "@emotion/styled";
import { Button } from "antd";
import { BellOutlined } from "@ant-design/icons";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 23px;
  height: 23px;
  object-fit: cover;
`;

const languageOptions = {
  en: {
    icon: "/static/img/flags/us.png",
    name: "English",
  },
  jp: {
    icon: "/static/img/flags/jp.png",
    name: "日本",
  },
};

function NavbarNotification(props) {
  const { outerClassName } = props;
  
  return (
    <div className={" " + outerClassName}>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
          <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
        </svg>
      </div>
    </div>
  );
}

export default NavbarNotification;
