import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "data",
  initialState: {
    key: "data",
    list: [],
    detail: {},
    status: "",
    message: "",
    paging: {
      limit: 20,
      current: 1,
    },
    
  },
  reducers: {
    setData: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setData } = slice.actions;

export default slice.reducer;
