import React from "react";

const Render = ({
    t,
    mode,
    outerClassName,
    events,
    activeKey,
    items
}) => {

    return (
        <div className={outerClassName}>
            {items.map((item, index) => {
                return (
                    <div 
                        index={item.key} 
                        className={item.className + " py-2 px-4 inline-block align-middle text-center rounded cursor-pointer"} 
                        onClick={() => events.onChange(item.key)}
                        style={{
                            backgroundColor: item.key == activeKey && "#3278BE" || "#E4F0FC",
                            color: item.key == activeKey && "#FFFFFF" || "#586772",
                            fontWeight: item.key == activeKey && 500 || 300 
                        }}>
                        {item.label}
                    </div>
                )
            })}
        </div>
    );
};

export default Render;