import React from "react";

import { Link } from "react-router-dom";

import {
  Table,
  Select,
  Form,
  Button
} from "antd";
import Title from "../title/Title02";
import { EventNoteSharp } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { companySelector } from "../../redux/selectors";
import { Input } from "@mui/material";

const Trade = ({t, data, title, events, form}) => {

  var columns = [
    {
      title: t("main_column_symbol"),
      // dataIndex: "symbol",
      key: "main_column_symbol",
      render: item => <Link to={"/company/detail?id=" + item?.code} className="text-blue-600 font-bold uppercase">{item?.name}</Link> 
    },
    {
      title: t("main_column_closedprice"),
      dataIndex: "closePrice",
      key: "main_column_closedprice",
      render: item => <div className="text-right font-bold">{item.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
    },
    {
      title: t("main_column_change"),
      dataIndex: "change",
      key: "main_column_change",
      render: item => <div className={`text-right ${Math.sign(item) == -1 && "text-red-500" || "text-green-500"}`} >{item}</div>
    },
  ];

  
  const Footer = ({}) => {
    // console.log("$:form/data ", data.company);
    return (
      <div className="text-center">
        <Form
          form={form}
          initialValues={{
            symbol: data.company.name,
            closePrice: data.company.closePrice,
            change: data.company.change
          }}
          onFinish={events.onClick}
        >
          <Form.Item
            label="Симбол"
            name="symbol"
            rules={[{ required: true, message: 'Симбол сонгоно уу!' }]}
          >
            <Select        
              showSearch      
              placeholder={t("main_company")}
              fieldNames={{
                label: "name",
                value: "code"
              }}
              options={data?.company}
            />
          </Form.Item>
          <Form.Item
            name="symbol"
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="closePrice"
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="change"
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button className="bg-blue-700" type="primary" htmlType="submit">{t("main_watchlist_add")}</Button>
          </Form.Item>
        </Form>
      </div>
    )
  };

  return (
    <div className="mb-4">
      <Title title={title} />
      <Table 
        size="small"
        rowClassName={(record, index) => index % 2 === 0 && "bg-blue-200 custom-row-hover" || "bg-blue-50 custom-row-hover"}
        className="border border-gray-300 rounded-lg table-small table-blue"
        pagination={false}
        columns={columns} 
        dataSource={data?.watchlist} 
        rowKey="id" 
        footer={() => data?.company.length > 0 && <Footer />}
        scroll={{ y: 240 }}
      />
      {/* <Footer /> */}
    </div>
  );
};

export default Trade;