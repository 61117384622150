import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import GlobalStyle from "../components/GlobalStyle";
import Settings from "../components/Settings";

const drawerWidth = 258;

const Root = styled.div`
  height: 100vh;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  display: grid;
  height: 100vh;
  align-content: space-between;
  align-items: center;
  margin: 0 auto !important;
  padding: 0 !important;
  justify-content: center;
`;

const Auth = ({ children }) => {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <MainContent p={isLgUp ? 12 : 5}>
        <Navbar />
        <GlobalStyle />
        {children}
        <Outlet />
        <div>
          <Footer />
        </div>
        <Settings />
      </MainContent>
    </Root>
  );
};

export default Auth;
