import React from "react";
import Marquee from "react-fast-marquee";
import {
  Select
} from "antd";
import Card05 from "../card/Card05";
import { WifiOutlined } from "@mui/icons-material";

const Trade = ({t, data}) => {
  
  return (
    <div className="">
      <div className="grid grid-cols-12 gap-4 items-center">
        {/* <div className="col-span-2 flex justify-between">
          <div className="flex text-green-500 text-xs items-center">
            <WifiOutlined className="mr-2 text-xs" style={{ fontSize: '14px' }} />
            {t("main_stableconnection")}
          </div>
        </div> */}
        <div className="col-span-12 mr-1">
          <Marquee direction="right">
            {data?.map((item, index) => {
              var changep = ((item?.closingPrice != null && item?.closingPrice || item?.lastTradedPrice) * 100 / item?.previousClose) - 100;
              return (
                <Card05 
                  index={index}
                  title={item?.symbol}
                  subtitle={changep.toFixed(2)}
                  description={item?.closingPrice != 0 && item?.closingPrice.toFixed(1) || item?.previousClose.toFixed(1)}
                />
            )})}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Trade;