import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "modal",
  initialState: {
    modal: false,
    mode: "",
    events: null,
    message: "",
    status: "",
    redirect: "",
  },
  reducers: {
    setModal: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setModal } = slice.actions;

export default slice.reducer;
