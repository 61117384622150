import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  message: "",
};

const slice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoader: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setLoader } = slice.actions;

export default slice.reducer;
