import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      main_symbol: "Симбол",
      auth_form_else: "Эсвэл",
      auth_form_name: "Нэр",
      auth_form_email: "Имэйл",
      auth_form_password: "Нууц үг",
      auth_form_signin: "Нэвтрэх",
      auth_form_register: "Бүртгүүлэх",
      auth_form_login: "Нэвтрэх",
      auth_form_submit: "Sign in",
      // main_column_totalshare: "Нийт гаргасан үнэт цаас",
      company_intro: "Танилцуулга",
      data_trade_today: "Арилжааны өдөр",
      company_stats: "Онцлох статистик",
      company_owner:"Гол эзэмшигч",
      Company_news: "Мэдээ",
      company_superiors: "Удирдлага - СЕО, ТУЗ",
      company_ownerplan: "Хувьцаа эзэмшигчдийн хөдөлгөөн",
      company_deal: "Арилжааны бус хэлцэл",
      company_chart:"Эзэмшигчдийн бүтэц",
      company_balance:"Балансын үзүүлэлт",
      company_income:"Орлогын үзүүлэлт",
      company_startdate:"Эхлэх огноо",
      company_enddate:"Дуусах огноо",
      company_history:"Арилжааны түүх",
      company_trading:"Багцын арилжаа",
      company_order:"Захиалгын сан",
      main_symbol_mnt: "₮",
      main_symbol_usd: "$",
      main_currency:"Валют",
      main_currency_mnt:"төгрөгөөр",
      error_auth_email_required: "Email is required",
      error_auth_email_wrong: "Must be a valid email",
      error_auth_pass_required: "Password is required",
      error_wrong: "Something went wrong",
      home_welcome: "Welcome back",
      link_dashboard: "Dashboard",
      link_main: "Yuboku Sama",
      link_home: "Нүүр хуудас",
      link_home_data: "Нүүр",
      link_grid: "Grid",
      link_settings: "Settings",
      link_settingscreate: "Create settings",
      main_not_found: "Таны хайсан хуудас олдсонгүй.",
      main_abnormal: "Abnormal",
      main_appauthkey: "App auth key",
      main_appcreate: "Create settings",
      main_appid: "App id",
      main_appurl: "URL",
      main_both: "Both",
      main_board_insider: "Том хувьцаа эзэмшигчдийн хөдөлгөөн",
      main_board_change: "Хувьцааны өөрчлөлт",
      main_board_local: "Зах зээлийн мэдээ",
      main_board_global: "Гадаад",
      main_board_up: "Топ өсөлт",
      main_board_top: "Хувьцааны өөрчлөлт",
      main_board_down: "Топ бууралт",
      main_board_signal: "Хувьцааны дохио",
      main_board_analysis: "Анализ",
      main_board_pack: "Сүүлд хийгдсэн багц арилжаа",
      main_board_deal: "Арилжааны бус хэлцэл",
      main_board_movement: "Арилжааны бус шилжилт",
      main_board_unit: "Нэгж эрх",
      main_board_hbuts: "ХБҮЦ",
      main_board_invest: "Хөрөнгө оруулагчдын арилжаа",
      main_board_san: "ХОСан",
      main_board_others: "Бусад үнэт цаас",
      main_board_bond: "Бонд",
      main_board_inherit: "Өв",
      main_board_gift: "Бэлэг",
      main_board_ss: "ШШ",
      main_board_ug: "ҮГ",
      main_board_otc: "Биржийн бус зах зээл / M-OTC",
      main_camera: "Camera",
      main_cauntion: "Энэхүү мэдээ нь \"Marketinfo.mn\"-ийн оюуны өмч тул зөвшөөрөлгүйгээр хэвлэн нийтлэх, хуулбарлан олшруулахыг хориглоно.",
      main_comment: "Сэтгэгдэл",
      main_column_mongolian: "Монголын хөрөнгө оруулагчид",
      main_column_global: "Гадаадын хөрөнгө оруулагчид",
      main_column_over_percent: "5%-иас их эзэмшил",
      main_column_under_percent: "5%-иас бага эзэмшил",
      main_column_lcname: "ХК-ийн нэр",
      main_column_sector: "Салбар",
      main_column_invest_local: "Дотоод хөрөнгө оруулагчдын арилжаа",
      main_column_invest_global: "Гадаадын хөрөнгө оруулагчдын арилжаа",
      main_column_invest_buy_turover: "Авсан үнийн дүн",
      main_column_invest_buy_vol: "Авсан ширхэг",
      main_column_invest_sell_turover: "Зарсан үнийн дүн",
      main_column_invest_sell_vol: "Зарсан ширхэг",
      main_column_numericvalue: "Тоон утга",
      main_column_trade_global: "Гадаадын хийсэн арилжаа",
      main_column_total_amount: "Арилжааны нийт дүн",
      main_column_datet: "Хугацаа",
      main_column_rev: "Нэгж хувьцааны ногдол ашиг",
      main_column_perrev: "Цэвэр ашигт эзлэх хувь",
      main_column_shareamount: "Мөнгөн дүн",
      main_column_percent: "Хувь",
      main_column_revenue: "Ногдол ашгийн өгөөж",
      main_column_share: "Тараасан хувь",
      main_column_name: "Нэр",
      main_column_branch: "Салбар",
      main_column_pricet: "Үнэ",
      main_column_piece: "Нэгж хувьцааны ханш",
      main_column_totalamount: "Нийт мөнгөн дүн",
      main_column_openprice: "Нээлтийн ханш",
      main_column_high: "Дээд",
      main_column_low: "Доод",
      main_column_buy: "Авах",
      main_column_buyed: "Авсан",
      main_column_selled: "Зарсан",
      main_column_sell: "Зарах",
      main_column_type: "Төрөл",
      main_column_research: "Судалгааны нэр",
      main_column_report: "Тайлангийн нэр",
      main_column_download: "Татах",
      main_column_difference: "Зөрүү",
      main_column_symbol: "Симбол",
      main_column_source: "Бэлтгэсэн",
      main_column_file: "Файл",
      main_column_date: "Огноо",
      main_column_montly: "Сарын тайлан",
      main_column_closedprice: "Хаалт",
      main_column_change: "Өөрчлөлт дүн",
      main_column_changep: "Өөрчлөлт хувь",
      main_column_amount: "Ширхэг",
      main_column_total: "Үнийн дүн",
      main_column_registereddate: "Биржид бүртгэгдсэн",
      f: "Салбарын нэр",
      main_column_price: "ЗЗҮнэлгээ/тэр.₮",
      main_column_pricet: "Үнэ",
      main_column_tradeprice: "Арилжсан үнэ",
      main_column_begindate: "хлэх огноо",
      main_column_enddate: "Дуусах огноо",	
      main_column_daterange: "Хамрах хугацаа",
      main_column_goverment: "Төрийн мэдэлд",
      main_column_owner: "Эзэмшигч",
      main_column_owner_ocount: "Эзэмшигчийн тоо",
      main_column_totalshare: "Нийт гаргасан ҮЦ",
      main_column_percent: "Хувь",
      main_column_product: "Бүтээгдэхүүн",
      main_column_qty: "Тоо хэмжээ",
      main_column_callprice: "Дуудсан үнэ",
      main_column_callprice: "Дуудсан үнэ",
      main_column_share: "Хувьцаа",
      main_signal: "Зах зээлийн дохио",
      main_column_impactful: "Том хувьцаа эзэмшигч",
      main_column_smallowners: "Жижиг хувьцаа эзэмшигч",
      main_column_owner_amount: "Тоо ширхэг",
      main_column_owner_count: "Тоо",
      main_column_owner_percent: "Хувь",
      main_column_trade: "Тухан өдрийн ханш",
      main_column_lastprice: "Сүүлийн ханш",
      main_column_order: "Захиалгын сан",
      main_column_registration: "Арилжааны бүртгэл",
      main_column_numberoftrade: "Авсан эсвэл зарсан тоо",
      main_column_amount_local: "Дотоодын эзэмшил ширхэг",
      main_column_amount_global: "Гадаадын эзэмшил ширхэг",
      main_column_deal: "Арилжааны бүртгэл",
      main_column_deal_time: "Цаг",
      main_column_deal_price: "Үнэ",
      main_column_marketcap: "Зах зээлийн үнэлгээ",
      main_column_deal_amount: "Ширхэг",
      main_column_quarter: "Улирал",
      main_column_description: "Тайлбар",
      main_date_last: "Сүүлд шинэчлэгдсэн",
      main_yeasterday:"Өчигдөр",
      main_halfyear:"Хагас жил",
      main_today:"Өнөөдөр",
      main_week:"7 хоног",
      main_month:"Сар",
      main_year:"Жил",
      main_column_code:"Код",
      main_feed_live: "Бодит хугацаа",
      main_feed_historical: "Түүхэн",
      company_text1:"+4.0%",
      company_text2:"(+0.27%)",
      main_company: "Компани",
      main_company_divident: "Ногдол ашиг",
      main_company_divident_date: "Ногдол ашгийн хамрах огноо",
      main_mse: "MSE:",
      main_trade_previousclose: "Өмнөх хаалт",
      main_trade_closedprice: "Хаалтын ханш",
      main_trade_amount: "Үнийн дүн",
      main_owners: "Эзэмшигчид",
      main_otc: "OTC маркет",
      main_finance: "Санхүү",
      main_graph: "График",
      main_tradehistory: "Арилжааны дата",
      main_analysis: "Анализ",
      main_contactus: "Холбоо барих",
      main_trade_traded:"Арилжигдсан ширхэг",
      main_trade_share:"Хувьцааны тоо",
      main_trade_market:"Зах зээлийн үнэлгээ",
      main_trade_eps:"EPS",
      main_trade_pe:"P/E",
      main_trade_bv:"BV",
      main_trade_industry:"Салбар",
      main_created: "Created at",
      main_detail: "Camera detail",
      main_delete: "",
      main_error: "Амжилтгүй",
      main_error_settings: "Failed to upload settings.",
      main_error_settings_deleted: "Failed to delete settings",
      main_form_startdate: "Эхлэх огноо",
      main_form_quarter: "Улирал",
      main_form_enddate: "Дуусах огноо",
      main_home: "Эхлэл",
      main_image: "Image",
      main_index: "Индекс~~",
      main_ltd: "BKO, Ltd.",
      main_last_updated: "Сүүлд шинэчлэгдсэн",
      main_normal: "Normal",
      main_news: "Мэдээ",
      main_news_local: "Зах зээлийн мэдээ",
      main_news_global: "Гадаад мэдээ",
      main_navbar_description: "Үнэт цаасны багц удирдлага ба статистик судалгаа",
      main_more_datamarketinfo: "Илүү ихийг data.marketinfo.mn-ээс",
      main_powered: "",
      main_preview: "Preview",
      main_pick_date: "Өдөр сонгох",
      main_remove: "Remove",
      main_research: "Судалгаа",
      main_column_watch: "Үзэх",
      main_report: "Тайлан",
      main_search: "Search",
      main_settingslist: "Settings",
      main_success: "Амжилттай",
      main_success_settings: "Settings uploaded successfully",
      main_success_settings_deleted: "Settings deleted successfully",
      main_status_mse: "MSE",
      main_status_ubex: "UBEX",
      main_stableconnection: "Stable connection",
      main_submit: "Submit",
      main_table_index: "Индекс",
      main_trade_today: "Арилжаа - Realtime",
      main_trade_watchlist: "Watchlist",
      main_trade_calendar: "Календар",
      main_trade_ipo: "Шинэ хөрөнгө оруулалт",
      main_total: "Total",
      main_top_20: "ТОП-20",
      main_top_day: "Day",
      main_top_week: "Week",
      main_top_month: "Month",
      main_top_year: "Year",
      main_more: "Дэлгэрэнгүй",
      main_more_all: "Бүгдийг харах",
      main_more_news: "Бусад",
      main_theme: "Theme",
      main_theme_dark: "Dark",
      main_theme_light: "Light",
      main_theme_default: "Default",
      main_theme_blue: "Blue",
      main_theme_green: "Green",
      main_theme_indigo: "Indigo",
      main_updated: "Updated at",
      main_watchlist_add: "ADD WATCHLIST",
      main_week_last: "7 хоног",
      main_viewmore: "view more",
      main_company_structure: "Эзэмшигчдийн бүтэц",
      main_company_owner: "Гол эзэмшигч", 
      main_company_movement: "Хувьцаа эзэмшигчдийн хөдөлгөөн",
      main_company_deal: "Арилжааны бус хэлцэл",
      main_company_statistics:"Онцлох статистик",
      main_company_intro:"Танилцуулга",
      main_company_suggestion:"Зөвлөмж", 
      main_company_analyze: "Ноогдол ашгийн анализ",
      main_company_news:"Мэдээ",
      main_company_boards:"Удирдлага",
      main_buy:"Авах",
      main_hold:"Хүлээ",
      main_sell:"Зарах",
      main_status_active: "Нээлттэй",
      main_status_inactive: "Хаалттай",
      main_time_range:"Хамрах хугацаа",
      main_company_balance: "Балансын үзүүлэлт",
      main_company_income: "Орлогын үзүүлэлт",
      main_company_networt:"Нийт хөрөнгө", 
      main_company_currentasset:"Эргэлтийн хөрөнгө", 
      main_company_liabilities:"Өр төлбөр", 
      main_company_noncurrentasset:"Эрэлтийн бус хөрөнгө", 
      main_company_property:"Эзэмшигчийн өмч", 
      main_company_netsales:"Цэвэр борлуулалт", 
      main_company_bb:"ББӨ", 
      main_company_otherincome:"Бусад орлого", 
      main_company_operationcost:"Үйл ажиллагааны зардал", 
      main_company_netprofit:"Цэвэр ашиг", 
      main_quarter: "Улирал",
      main_halpyear: "Хагас жил",
      main_company_tradinghistory: "Арилжааны түүх",
      main_company_packtrade: "Багцыг арилжаа",
      main_company_orderhistory: "Захиалгын сан",
      main_download_xlsx: "XLS татах",
      main_company_finance_eps: "EPS",
      main_company_finance_bvps: "BV",
      main_company_finance_t_uliral: "Улирал",
      main_company_finance_pE_Ratio: "PE",
      main_company_finance_pB_Ratio: "PB",
      main_company_finance_pS_Ratio: "PS",
      main_company_finance_npm: "NPM",
      main_company_finance_gpr: "GPR",
      main_company_finance_dP_Ratio: "DP",
      main_company_finance_dY_Ratio: "DY",
      main_company_finance_roe: "ROE",
      main_company_finance_roa: "Roa",
      main_company_finance_va_zardal: "4. Үйл ажиллагааны зардал",
      main_stock_signal: "Дохио",
      main_column_symbol: "Симбол",
      main_column_status: "Төлөв",
      main_company_roa: "Нийт хөрөнгийн өгөөж /ROA/",
      main_company_roe: "Өөрийн хөрөнгийн өгөөж /ROE/",
      main_company_ratiorevenue: "Нийт ашгийн хувь",
      main_company_marginpureincome: "Цэвэр ашгийн маржин",
      main_company_ratioincome: "Ногдол ашгийн өгөөж",
      main_company_finance_niit_hurungu: "Нийт хөрөнгө",    
      main_company_finance_ergelt_hurungu: "Эргэлтийн хөрөнгө",	     
      main_company_finance_ergelt_bus_hurungu: "Эргэлтийн бус хөрөнгө",	
      main_company_finance_ur_tulbur: "Өр төлбөр",	        
      main_company_finance_ezemshigch_umch: "Эзэмшигчийн өмч",	
      main_company_finance_tsever_borluulalt: "1. Цэвэр борлуулалт",	
      main_company_finance_tsever_ashig: "5. Цэвэр ашиг",	    
      main_company_finance_bbu: "2. Борлуулсан бүтээгдэхүүний өртөг",	            
      main_company_finance_busad_orlogo: "3. Бусад орлого",       
      main_percent: "Ноогдол ашгийн хувь",
      main_revenue: "Ноогдол ашгийн өгөөж",
      main_time_range_now:"Одоо",
      main_time_range_quarter:"Дараагийн 3 сар",
      main_time_range_halfyear:"Дараагийн 6 сар",
      main_price:"Зорилтот ханш",
      main_pricet:"Үнэ",
      main_more_short: "Үзэх",
      menu_home: "Эхлэл",
      menu_news: "Мэдээ",
      main_board_ownership: "Хувьцааны эзэмшил",
      menu_more_news: "Бусад мэдээ",
      menu_package: "Миний багц",
      menu_watchlist: "Watchlist",
      menu_investment: "Хөрөнгө оруулалт",
      menu_investment_new: "Шинэ үнэт цаас",
      menu_investment_how: "Хэрхэн арилжаа хийх",
      menu_investment_company: "Брокерийн компани",
      menu_investment_share: "Үнэт цаас гаргах",
      menu_marketdata: "Маркет дата",
      menu_marketdata_market: "Маркет",
      menu_marketdata_index: "Индекс",
      menu_marketdata_todaytrade: "Тухайн өдрийн ханш",
      menu_marketdata_portfolio: "Миний багц",
      menu_marketdata_portfolio_new: "Шинэ багц",
      menu_marketdata_change: "Өсөлт бууралт",
      menu_marketdata_trade: "Арилжааны идэвхи",
      menu_marketdata_pack: "Багцын арилжаа",
      menu_marketdata_analysis: "Анализ",
      menu_marketdata_signal: "Дохио",
      menu_marketdata_otc: "M-OTC маркет",
      menu_marketdata_deal: "Арилжааны бус хэлцэл",
      menu_marketdata_movement: "Эзэмшлийн хөдөлгөөн",
      menu_marketdata_bigmovement: "Том эзэмшигчдийн хөдөлгөөн",
      menu_marketdata_trade: "Ханшийн өөрчлөлт",
      menu_marketdata_shareowner: "Хувьцааны эзэмшил",
      menu_marketdata_companies: "Хувьцаат компаниуд",
      menu_marketdata_report: "Маркет тайлан",
      menu_marketdata_mining: "Уул уурхайн арилжаа",
      menu_marketdata_research: "Судалгаа",
      menu_announcement: "Announcement",
      main_hour: "Цаг",
      menu_cookie: "Cookie preferences",
      menu_downloads: "Downloads",
      menu_online_support: "Online support",
      contact_location: "2972 Westheimer Rd. Santa Ana Illinos 85486",
      contact_email: "marketinfo.mgl@gmail.com",
      contact_number:"(976) 9920-3430",
      contact_collaborate: "Хамтран ажиллах",
      contact_subscribe: "Хэрэглэгч болох",
      contact_aboutus: "Бидний тухай",
      contact_publisher: "Нийтлэгч", 
      contact_description: "Та Маркет дата-с зах зээлийн голлох үзүүлэлтүүд, хувьцаат компанийн тоо баримт, тэдгээрийн өөрчлөлт хөдөлгөөн зэрэг судалгаа шинжилгээ хийхэд шаардлагатай бүхий л тоон мэдээллүүдийг авах болно.", 
      footer_marketdata: "Маркет дата",
      footer_terms: "Үйлчилгээний нөхцөл",
      footer_policy: "Нууцлалын бодлого",
      main_portfolio_name: "Багцын нэр",
      main_portfolio_add: "Багц нэмэх",
      main_portfolio_investment: "Багцын хөрөнгө оруулалт",
      main_portfolio_marketvalue: "Багцын зах зээлийн үнэ цэнэ",
      main_portfolio_value: "Зах зээлийн үнэ цэнэ",
      main_portfolio_revenue: "Ашиг/Алдагдал",
      main_portfolio_percent: "%",
      main_portfolio_edit: "Засах",
      main_portfolio_delete: "Устгах",
      main_portfolio_button: "Нэмэх",
      main_portfolio_success: "Амжилттай",
      main_portfolio_success_add: "Багц амжилттай нэмлээ.",
      main_portfolio_success_edit: "Багц амжилттай заслаа.",
      main_share_success_add: "Хувьцаа амжилттай нэмлээ.",
      main_share_success_edit: "Хувьцаа амжилттай заслаа.",
      main_portfolio_edited_failed_add: "Багц нэмэхэд алдаа гарлаа.",
      main_portfolio_edited_failed_edit: "Багц засахад алдаа гарлаа.",
      main_portfolio_edited_failed_delete: "Багц усгахад алдаа гарлаа.",
      main_portfolio_register: "Бүртгэсэн",
      main_portfolio_price: "Авсан үнэ",
      main_portfolio_fee: "Шимтгэл /хувиар/",
      main_share_add: "Хувьцаа нэмэх",
      main_link_home: "Нүүр хуудас",
      main_link_portfolio: "Миний багц",
      main_link_shares: "Хувьцаа"
    },
  },
  jp: {
    translation: {
      auth_form_email: "電子メールアドレス",
      auth_form_password: "パスワード",
      auth_form_submit: "ログイン",
      error_wrong: "エラーが発生しました",
      error_auth_email_wrong: "有効な電子メールである必要があります",
      error_auth_email_required: "メールアドレス必須",
      error_auth_pass_required: "パスワードが必要です",
      home_welcome: "おかえり",
      link_main: "Yuboku",
      link_dashboard: "ダッシュボード",
      link_grid: "グリッド",
      link_settings: "設定",
      link_settingscreate: "設定を作成する",
      main_abnormal: "異常",
      main_appauthkey: "アプリ認証キー",
      main_appcreate: "設定を作成する",
      main_appid: "アプリ ID",
      main_appurl: "URL",
      main_both: "両方",
      main_camera: "カメラ",
      main_created: "日付",
      main_detail: "カメラ詳細",
      main_delete: "消去",
      main_error: "失敗した",
      main_error_settings: "設定のアップロードに失敗しました。",
      main_error_settings_deleted: "設定の削除に失敗しました",
      main_image: "画像",
      main_ltd: "ad-dice co., Ltd.",
      main_normal: "正常",
      main_powered: "Powered by SoLoMoN AI",
      main_preview: "プレビュー",
      main_remove: "削除する",
      main_search: "検索",
      main_settingslist: "設定",
      main_success: "成功",
      main_success_settings: "設定が正常にアップロードされました",
      main_success_settings_deleted: "設定が正常に削除されました",
      main_status: "スターテス",
      main_submit: "送信",
      main_total: "合計",
      main_theme: "テーマ",
      main_theme_dark: "ダーク",
      main_theme_light: "ライト",
      main_theme_default: "デフォルト",
      main_theme_blue: "ブルー",
      main_theme_green: "緑",
      main_theme_indigo: "緑",
      main_updated: "に更新",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

