import React from "react";
import styled from "@emotion/styled";
import NavbarLogo from "./navbar/NavbarLogo";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import {
  PhoneFilled,
  MailOutlined,
  MailFilled, 
  EnvironmentFilled,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

const Footer = ({data}) => {

  const { t } = useTranslation();

  return (
    <footer className="grid grid-cols-12 bg-gray-700 text-white flex justify-center ">
      <div className="col-span-3 px-5 py-10">
        <ContactUs t={t} />
      </div>
      <div className="col-span-6 text-center">
        <MainSection t={t}/>
      </div>
    </footer>
  )
}

export default Footer;

const ContactUs = ({t}) => {
  return (
    <div className="">
      <div className="text-white text-base font-semibold mb-5">{t("main_contactus")}</div>
      <div className="my-2">
        <span className=" px-2 py-3 text-xl align-middle text-slate-50"><PhoneFilled /></span>{t("contact_number")}
      </div>
      <div className="my-2">
        <span className="px-2 py-3 text-xl align-middle text-slate-50"><MailFilled /></span>{t("contact_email")}
      </div>
      {/* <div className="my-2 text-justify col-span-3">
        <span className="px-2 py-3 text-xl align-middle text-slate-50"><EnvironmentFilled /></span>{t("contact_location")}</div> */}
    </div>
  )
}

const MainSection = ({t}) => {

  return (
    <div className="pt-8">
      {/* <div className="mb-8 flex justify-center my-8">
       <NavbarLogo />
      </div> */}
      <div className="flex justify-center">
        <div className="mr-5 "><Link to="/aboutus">{t("footer_marketdata")}</Link></div>
        <div className="mr-5 "><Link to="/aboutus/terms">{t("footer_terms")}</Link></div>
        <div className="mr-5 "><Link to="/aboutus/policy">{t("footer_policy")}</Link></div>
      </div>
      <div className="flex justify-center row-span-2 text-center my-5">{t("contact_description")}</div>
      <div className="text-xl my-6 ">
        <span className="px-2"><Link to="https://www.facebook.com/Marketinfo.mn" target="_blank"><FacebookOutlined /></Link></span>
        {/* <span className="px-2"><Link to="/@twitter"><TwitterOutlined /></Link></span>
        <span className="px-2"><Link to="/@Linkedin"><LinkedinOutlined /></Link></span>
        <span className="px-2"><Link to="/@Instagram "><InstagramOutlined /></Link></span> */}
      </div>
   </div>
  )
}